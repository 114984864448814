import twinRoomImg from '../../../assets/imgs/home/hero/01_spinut.webp';
import superiorTwinImg from '../../../assets/imgs/home/hero/02_bb.webp';

const BbUnitsList = ({ lang }: { lang: Locale.Keys }) => {
  const twinRoom: Unit = {
    unitTitle: lang.hotels.bb.twin.title,
    unitDescription: lang.hotels.bb.twin.p1,
    unitImg: twinRoomImg,
  };

  const tripleRoom: Unit = {
    unitTitle: lang.hotels.bb.triple.title,
    unitDescription: lang.hotels.bb.triple.p1,
    unitImg: superiorTwinImg,
  };

  const twinRoomSea: Unit = {
    unitTitle: lang.hotels.bb.twinSea.title,
    unitDescription: lang.hotels.bb.twinSea.p1,
    unitImg: twinRoomImg,
  };

  const tripleRoomSea: Unit = {
    unitTitle: lang.hotels.bb.tripleSea.title,
    unitDescription: lang.hotels.bb.tripleSea.p1,
    unitImg: superiorTwinImg,
  };

  return [twinRoom, tripleRoom, twinRoomSea, tripleRoomSea];
};

export default BbUnitsList;
