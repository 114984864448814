import { Location, NavigateFunction } from 'react-router-dom';
import HeroSlideshow from '../../components/hero_slideshow/HeroSlideshow';
import Constants from '../../utils/Constants';
import About from './components/about/About';
import Accommodations from './components/accommodations/Accommodations';
import Variables from '../../style/variables.scss';
import Discover from './components/discover/Discover';
import { CapitalizeFirstLetter } from '../../utils/CapitalizeFirstLetter';
import { useEffect } from 'react';

const Home = ({
  lang,
  location,
  navigate,
}: {
  lang: Locale.Keys;
  location: Location;
  navigate: NavigateFunction;
}) => {
  useEffect(() => {
    document.title = `${CapitalizeFirstLetter(Constants.PROPERTY_NAME)}`;
  }, []);
  return (
    <div id={Constants.HOME} className='home'>
      <div className='home_container_hero'>
        <HeroSlideshow lang={lang} />
      </div>
      <h1 hidden>{Constants.PROPERTY_NAME}</h1>
      <About lang={lang} />
      {Constants.separatorColoredDown(Variables.primaryColor)}
      <Accommodations lang={lang} location={location} navigate={navigate} />
      <Discover lang={lang} />
      {Constants.separatorColoredUp(Variables.primaryColor)}
    </div>
  );
};

export default Home;
