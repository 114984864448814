import twinRoomImg from '../../../assets/imgs/home/hero/01_spinut.webp';
import superiorTwinImg from '../../../assets/imgs/home/hero/02_bb.webp';

const KampusUnitsList = ({ lang }: { lang: Locale.Keys }) => {
  const twinRoom: Unit = {
    unitTitle: lang.hotels.kampus.twin.title,
    unitDescription: lang.hotels.kampus.twin.p1,
    unitImg: twinRoomImg,
  };

  const singleRoom: Unit = {
    unitTitle: lang.hotels.kampus.single.title,
    unitDescription: lang.hotels.kampus.single.p1,
    unitImg: superiorTwinImg,
  };

  const twinDelux: Unit = {
    unitTitle: lang.hotels.kampus.twinDelux.title,
    unitDescription: lang.hotels.kampus.twinDelux.p1,
    unitImg: twinRoomImg,
  };

  const doubleSuite: Unit = {
    unitTitle: lang.hotels.kampus.doubleSuite.title,
    unitDescription: lang.hotels.kampus.doubleSuite.p1,
    unitImg: superiorTwinImg,
  };

  const singleSuite: Unit = {
    unitTitle: lang.hotels.kampus.singleSuite.title,
    unitDescription: lang.hotels.kampus.singleSuite.p1,
    unitImg: twinRoomImg,
  };

  return [twinRoom, singleRoom, twinDelux, doubleSuite, singleSuite];
};

export default KampusUnitsList;
