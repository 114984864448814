import { useEffect } from 'react';
import Constants from '../../../utils/Constants';
import heroImg from '../../../assets/imgs/home/hero/02_bb.webp';
import { CapitalizeFirstLetter } from '../../../utils/CapitalizeFirstLetter';
import ScrollAnimation from '../../../utils/ScrollAnimation';
import Variables from '../../../style/variables.scss';
import Gallery from '../components/gallery/Gallery';
import Services from '../components/services/Services';
import Units from '../components/units/Units';
import BbUnitsList from './BbUnitsList';
import ServicesList from '../components/services/ServicesList';

const images = require.context('../../../assets/imgs/home/discover', true);
const imageList: Array<string> = images
  .keys()
  .map((image: any) => images(image));

const Bb = ({ lang }: { lang: Locale.Keys }) => {
  const { parking, wifi, fitness, ac, garden, breakfast } = ServicesList({
    lang,
  });

  const servicesListe = [parking, wifi, fitness, ac, garden, breakfast];
  const unitsList = BbUnitsList({ lang });

  useEffect(() => {
    document.title = `${CapitalizeFirstLetter(
      Constants.PROPERTY_NAME
    )} - ${CapitalizeFirstLetter(Constants.BB)}`;
  }, []);

  return (
    <div id={Constants.BB} className='hotels'>
      <div className='hotels_hero'>
        <img src={heroImg} alt={`${Constants.BB} hero`} />
        <div className='overlay'></div>
        <h1 className='hotels_hero_title'>
          {CapitalizeFirstLetter(Constants.BB)}
        </h1>
      </div>
      <div className='hotels_about'>
        <div className='container'>
          <h2 className='title_underline_light'>{lang.hotels.subtitle}</h2>
          <ScrollAnimation animateIn='fadeInUp' delay={100}>
            <p>{lang.hotels.bb.p1}</p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={150}>
            <p>
              {lang.hotels.bb.p2} {lang.hotels.bb.p3}
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={200}>
            <p>{lang.hotels.bb.p4}</p>
          </ScrollAnimation>
        </div>
      </div>
      {Constants.separatorColoredDown(Variables.primaryColor)}
      <Services lang={lang} services={servicesListe} />
      <Units lang={lang} unitsList={unitsList} />
      {Constants.separatorColoredUp(Variables.primaryColor)}
      <Gallery lang={lang} imageList={imageList} />
    </div>
  );
};

export default Bb;
