import { NavigateFunction, Outlet, Location } from 'react-router-dom';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

const Layout = ({
  currentLanguage,
  changeLanguage,
  lang,
  location,
  navigate,
}: {
  currentLanguage: string;
  changeLanguage: Function;
  lang: Locale.Keys;
  location: Location;
  navigate: NavigateFunction;
}) => {
  return (
    <div className='body_wrapper'>
      <Header
        currentLanguage={currentLanguage}
        changeLanguage={changeLanguage}
        lang={lang}
        location={location}
        navigate={navigate}
      />
      <Outlet />
      <Footer location={location} navigate={navigate} />
    </div>
  );
};

export default Layout;
