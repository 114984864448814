import { useEffect, useState } from 'react';
import { NavigateFunction, Location } from 'react-router-dom';
import logo from '../../assets/imgs/scst_logo_white.png';
import scrollToPage from '../../utils/ScrollToLink';
import { ScrollDirection } from '../../utils/ScrollDirection';
import Constants from '../../utils/Constants';
import Variables from '../../style/variables.scss';

const Header = ({
  currentLanguage,
  changeLanguage,
  lang,
  location,
  navigate,
}: {
  currentLanguage: string;
  changeLanguage: Function;
  lang: Locale.Keys;
  location: Location;
  navigate: NavigateFunction;
}) => {
  const direction = ScrollDirection();

  const handleLangChange = (event: any) => {
    const newLanguage = event.target.value;
    changeLanguage(newLanguage);
  };

  const [openMenu, setOpenMenu] = useState<boolean>(false);

  let header: HTMLElement | null = document.getElementById('nav_container');
  let headerBackground: HTMLElement | null =
    document.getElementById('nav_background');

  const handleLink = (target: string) => {
    setOpenMenu(!openMenu);
    scrollToPage(target, location, navigate);

    setTimeout(() => {
      if (header !== null && headerBackground !== null && window.scrollY > 96) {
        headerBackground.style.top = '-6rem';
        header.style.top = '-6rem';
      }
    }, 600);
  };

  const handelHeaderAppearance = () => {
    if (header !== null && headerBackground !== null) {
      if (direction === 'up') {
        headerBackground.style.top = '0';
        header.style.top = '0';

        if (window.scrollY < 96) {
          headerBackground.style.background = 'transparent';
        }
      } else if (direction === 'down') {
        headerBackground.style.top = '-6rem';
        header.style.top = '-6rem';

        if (window.scrollY >= 96) {
          headerBackground.style.background = Variables.complementaryColor;
        }
      }
    }
  };

  const langButton = () => {
    return (
      <select
        className='header_lang opacity_transition_low nav_book_lang'
        value={currentLanguage}
        onChange={handleLangChange}
        aria-label='Language'
      >
        <option value={Constants.lang.en}>
          {Constants.lang.en.toUpperCase()}
        </option>
        <option value={Constants.lang.hr}>
          {Constants.lang.hr.toUpperCase()}
        </option>
      </select>
    );
  };

  window.onscroll = () => {
    handelHeaderAppearance();
  };

  useEffect(() => {
    if (openMenu) {
      document.body.classList.add('lock_scroll');
      document.getElementById('nav_menu')?.classList.add('open_sidemenu');
    } else {
      document.body.classList.remove('lock_scroll');
      document.getElementById('nav_menu')?.classList.remove('open_sidemenu');
    }
  }, [openMenu]);

  return (
    <div className='nav_wrapper'>
      <div id='nav_container'>
        <div className='nav_menu'>
          <div className='nav_menu_burger'>
            <input
              className='nav_menu_burger_cheeckbox'
              type='checkbox'
              onChange={() => setOpenMenu(!openMenu)}
              checked={openMenu}
              aria-label='Burger'
            />
            <div>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <div className='nav_logo'>
          <img
            src={logo}
            alt={Constants.PROPERTY_NAME + ' logo'}
            id='nav_logo'
            className='pointer'
            onClick={() => scrollToPage('home', location, navigate)}
          />
        </div>
        <div className='nav_book'>
          {langButton()}
        </div>
      </div>
      <div id='nav_background'></div>
      <div id='nav_menu'>
        <div className='nav_menu_list'>
          <div className='nav_menu_list_links'>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.HOME)}
            >
              {lang.home.menu}
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.ABOUT)}
            >
              {lang.home.about.title}
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.ACCOMMODATIONS)}
            >
              {lang.home.accommodations.title}
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.DISCOVER)}
            >
              {lang.home.discover.title}
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.FOOTER)}
            >
              {lang.contactUs.title}
            </div>
            {langButton()}
          </div>
          <div className='nav_menu_list_image'></div>
        </div>
      </div>
    </div>
  );
};

export default Header;
