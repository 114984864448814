import { useEffect, useState } from 'react';
import Constants from '../../utils/Constants';
import HeroSlideshowList from './HeroSlideshowList';

const IMAGE_CONTAINER = 'hero_slideshow_image_container';
const ACTIVE_SLIDE = 'slide_active';
const LAST_SLIDE = 'slide_last';
const TIME_OUT = 5; // duration of transition in seconds

// duplicate first image to the last position

const HeroSlideshow = ({ lang }: { lang: Locale.Keys }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageContainer = document.getElementById(IMAGE_CONTAINER);

  const heroList = HeroSlideshowList({ lang });
  heroList?.push(heroList[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % heroList.length);
    }, TIME_OUT * 1000);

    return () => clearInterval(interval);
  }, [heroList.length]);

  if (imageContainer !== null) {
    setTimeout(
      () => {
        if (currentIndex === heroList.length - 1) {
          imageContainer.classList.add(LAST_SLIDE);
          setCurrentIndex(0);
        } else if (currentIndex === 0) {
          imageContainer.classList.remove(LAST_SLIDE);
        }
      },
      currentIndex === 0 ? 100 : 1100
    );
  }

  return (
    <div id='hero_slideshow'>
      <div
        id={IMAGE_CONTAINER}
        className={ACTIVE_SLIDE}
        style={{
          width: `${heroList.length * 100}vw`,
          transform: `translateX(${-currentIndex * 100}vw)`,
        }}
      >
        {heroList.map((obj, index) => (
          <div className='component'>
            <img
              key={index}
              src={obj.img}
              alt={`hero ${Constants.PROPERTY_NAME} ${index + 1}`}
            />
            <div className='component_overlay'>
              <h2>{obj.title}</h2>
              <a
                href={obj.link}
                target='_blank'
                rel='noreferrer'
                className='opacity_transition_low button'
              >
                <div>{lang.bookNow.toUpperCase()}</div>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeroSlideshow;
