import { Location, NavigateFunction } from 'react-router-dom';
import scrollToPage from '../../utils/ScrollToLink';
import smallLogo from '../../assets/imgs/scst_logo_white.png';
import Constants from '../../utils/Constants';

const Footer = ({
  location,
  navigate,
}: {
  location: Location;
  navigate: NavigateFunction;
}) => {
  return (
    <div id={Constants.FOOTER} className='footer'>
      <div className='footer_container container'>
        <div
          className='footer_container_logo'
          onClick={() => scrollToPage('home', location, navigate)}
        >
          <img src={smallLogo} alt={Constants.PROPERTY_NAME + ' small logo'} />
        </div>
        <div className='footer_container_contacts'>
          {(location.pathname === `/${Constants.SPINUT}` ||
            location.pathname === '/') && (
            <div className='footer_container_contacts_contact'>
              <h3>HOSTEL SPINUT</h3>
              <p>
                <a
                  href='https://maps.app.goo.gl/812f43yodjvnMxvR8'
                  target='_blank'
                  rel='noreferrer'
                  className='opacity_transition_low'
                >
                  Spinutska 39
                </a>
              </p>
              <p>21000 Split, Croatia</p>
              <p>
                <a href='---' className='opacity_transition_low'>
                  ---
                </a>
              </p>
              <p>
                <a href='mailto:---' className='opacity_transition_low'>
                  ---
                </a>
              </p>
            </div>
          )}

          {(location.pathname === `/${Constants.BB}` ||
            location.pathname === '/') && (
            <div className='footer_container_contacts_contact'>
              <h3>HOSTEL BB</h3>
              <p>
                <a
                  href='https://maps.app.goo.gl/wRYZx1W6HuXoqGT47'
                  target='_blank'
                  rel='noreferrer'
                  className='opacity_transition_low'
                >
                  Spinutska 37
                </a>
              </p>
              <p>21000 Split, Croatia</p>
              <p>
                <a href='---' className='opacity_transition_low'>
                  ---
                </a>
              </p>
              <p>
                <a href='mailto:---' className='opacity_transition_low'>
                  ---
                </a>
              </p>
            </div>
          )}

          {(location.pathname === `/${Constants.KAMPUS}` ||
            location.pathname === '/') && (
            <div className='footer_container_contacts_contact'>
              <h3>ROOMS KAMPUS</h3>
              <p>
                <a
                  href='https://maps.app.goo.gl/z4brj49sgRQMaT1H8'
                  target='_blank'
                  rel='noreferrer'
                  className='opacity_transition_low'
                >
                  Cvite Fiskovića 3
                </a>
              </p>
              <p>21000 Split, Croatia</p>
              <p>
                <a href='---' className='opacity_transition_low'>
                  ---
                </a>
              </p>
              <p>
                <a href='mailto:---' className='opacity_transition_low'>
                  ---
                </a>
              </p>
            </div>
          )}

        </div>
        <div className='footer_container_bottom'>
          <p>Copyright © SCST {new Date().getFullYear()}.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
