export const CapitalizeFirstLetter = (word: string) => {
    // Split the sentence into words
    const words = word.split('-').flatMap(word => word.split(' '));
  
    // Capitalize the first letter of each word
    const capitalizedWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
  
    // Join the words back into a sentence
    const capitalizedSentence = capitalizedWords.join(' ');
  
    return capitalizedSentence;
};
