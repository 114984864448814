import cityMap from '../../../../assets/documents/home/discover/CITY_MAP.pdf';
import walkingTour from '../../../../assets/documents/home/discover/WALKING_TOUR.pdf';

interface Feature {
  description: JSX.Element;
  imgPath: string;
  link?: string;
}

const images = require.context('../../../../assets/imgs/home/discover', true);
const imageList: Array<string> = images
  .keys()
  .map((image: any) => images(image));

const DiscoverList = ({ lang }: { lang: Locale.Keys }) => {
  const HISTORIC: Feature = {
    description: (
      <>
        <h2 className='title_underline_complementary_small'>
          {lang.home.discover.historic.title}
        </h2>
        <p>{lang.home.discover.historic.p1}</p>
        <p>{lang.home.discover.historic.p2}</p>
        <p>{lang.home.discover.historic.p3}</p>
        <a
          href='https://360.visitsplit.com/'
          target='_blank'
          rel='noreferrer'
          className='opacity_transition_low button'
        >
          <div>{lang.home.discover.historic.visitSplit}</div>
        </a>
      </>
    ),
    imgPath: imageList.find((x) => x.includes('historic')) || '',
  };

  const ENCHANTING: Feature = {
    description: (
      <>
        <h2 className='title_underline_complementary_small'>
          {lang.home.discover.enchanting.title}
        </h2>
        <p>{lang.home.discover.enchanting.p1}</p>
        <p>{lang.home.discover.enchanting.p2}</p>
        <p>{lang.home.discover.enchanting.p3}</p>
        <a
          href={walkingTour}
          target='_blank'
          rel='noreferrer'
          className='opacity_transition_low button'
        >
          <div>{lang.home.discover.enchanting.walkingTour}</div>
        </a>
        <a
          href={cityMap}
          target='_blank'
          rel='noreferrer'
          className='opacity_transition_low button'
        >
          <div>{lang.home.discover.enchanting.cityMap}</div>
        </a>
      </>
    ),
    imgPath: imageList.find((x) => x.includes('enchanting')) || '',
  };

  return [HISTORIC, ENCHANTING];
};

export default DiscoverList;
