import Slideshow from '../../../../components/slideshow/Slideshow';
import Constants from '../../../../utils/Constants';
import ScrollAnimation from '../../../../utils/ScrollAnimation';

const Gallery = ({
  lang,
  imageList,
}: {
  lang: Locale.Keys;
  imageList: string[];
}) => {
  return (
    <div id={Constants.GALLERY} className='gallery'>
      <div className='gallery_container container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeIn' duration={500} dellay={100}>
            <h2>{lang.gallery.title}</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeIn' dellay={150}>
            <div className='gallery_container_imgs'>
              <Slideshow slideshowImages={imageList} />
            </div>
          </ScrollAnimation>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Gallery;
