export const en: Locale.Keys = {
  bookNow: 'Book Now',
  showMore: 'Show More',
  home: {
    menu: 'home',
    hero: {
      hostelSpinut: 'Hostel Spinut',
      hostelBb: 'Hostel BB',
      roomsKampus: 'Rooms Kampus',
    },
    about: {
      title: 'studentski centar split',
      p1: 'Studentski centar osnovan je davne 1960.god. Počeci su bili teški,samo 60 mjesta u studentskom domu i skroman jelovnik u menzi tadašnjeg Omladinskog hostela.S vremenom su se kapaciteti povećavali izgradnjom doma Brune Bušića na istom predjelu grada Splita, a u 90-tim godinama prošlog stoljeća u neposrednoj blizini niknuo je i hostel Spinut.',
      p2: 'Studentski centar je svojim brojnim projektima snažna logistička podrška splitskom Sveučilištu u ostvarenju vizije Splita kao modernog studentskog grada sa svim pripadajućim sadržajima. Nalazimo se u zgradi Kampusa,na adresi Cvite Fiskovića 3.',
      p3: 'Studentski centar kao sastavnica Splitskog sveučilišta teži sve svoje aktivnosti usmjeriti ka podizanju razine studentskog standarda. Svojim smještajnim kapacitetima i uslugama prehrane na raspolaganju smo rastućoj studentskoj populaciji u gradu pod Marjanom koja trenutno prelazi broj od 22 000 studenata. Također posredujemo u njihovom povremenom zapošljavanju.',
    },
    accommodations: {
      title: 'accommodations',
    },
    discover: {
      title: 'discover split',
      historic: {
        title: 'Historic Split',
        p1: 'Welcome to the breathtaking city of Split, Croatia!',
        p2: 'Nestled on the stunning Dalmatian Coast, Split is a blend of ancient history and vibrant modern culture.',
        p3: `Home to the iconic Diocletian's Palace, a UNESCO World Heritage Site, this city offers a unique glimpse into Roman architecture intertwined with contemporary living.`,
        visitSplit: '360 VISIT SPLIT',
      },
      enchanting: {
        title: 'Enchanting Split',
        p1: 'Wander through charming cobblestone streets, explore bustling local markets, and indulge in delicious Mediterranean cuisine at seaside restaurants.',
        p2: 'With its picturesque beaches, clear blue waters, and lively nightlife, Split promises an unforgettable experience for every traveler.',
        p3: 'Come and discover the magic of Split at our beautiful hotel, your perfect gateway to this enchanting city.',
        cityMap: 'CITY MAP',
        walkingTour: 'WALKING TOUR',
      },
    },
  },
  hotels: {
    subtitle: 'enjoy your stay',
    services: {
      title: 'facilities and services',
      parking: 'free parking',
      wifi: 'free wifi',
      ac: 'air conditioning',
      bar: 'bar',
      terrace: 'terrace',
      garden: 'garden',
      restaurant: 'restaurant',
      breakfast: 'breakfast',
      fitness: 'fitness centre',
      noSmokeRooms: 'non-smoking rooms',
    },
    spinut: {
      p1: `Located at the foot of the green Marjan Hill, a 5-minute walk from a pebble beach and a 15-minute walk from Diocletian's Palace, Hostel Spinut offers en-suite rooms with balcony.`,
      p2: 'The restaurant and the café serve international and Croatian cuisine and a wide range of drinks. Wi-Fi is available free of charge in all areas.',
      p3: 'Football and basketball courts can be found on site. Free parking is available.',
      p4: `Last stop of Bus Line 17 is set near the hostel. Split's Bacvice beach and the ferry port can be reached on foot within 30 minutes. Split Airport is at a distance of 20 km.`,
      twin: {
        title: 'twin room',
        p1: 'The 2 beds can be put together. The room features a balcony and a private bathroom with shower. Towels and linen are provided.',
      },
      superiorTwin: {
        title: 'superior twin room',
        p1: 'This air-conditioned twin room features a private bathroom, a wardrobe, parquet floors as well as a balcony. The unit offers 2 beds.',
      },
    },
    bb: {
      p1: `Boasting a garden, shared lounge and views of garden, Hostel BB is situated in Split, less than 1 km from Prva Voda Beach. The property is close to Meštrović Gallery, People's Square - Pjaca and Republic Square - Prokurative. Guests can have a drink at the snack bar.`,
      p2: 'At the hostel, the rooms come with a balcony. With a private bathroom fitted with a shower and a hairdryer, rooms at Hostel BB also feature free WiFi, while selected rooms also feature a sea view. All rooms at the accommodation have air conditioning and a desk.',
      p3: 'Guests at Hostel BB can enjoy a buffet or a continental breakfast.',
      p4: `Popular points of interest near the hostel include Diocletian's Palace, Stadium Poljud and Split Archaeological Museum. The nearest airport is Split Airport, 23 km from Hostel BB.`,
      twin: {
        title: 'twin room',
        p1: 'The twin room features air conditioning, refrigerator, a seating area, a balcony with garden views as well as a private bathroom boasting a shower. The unit has 2 beds.',
      },
      triple: {
        title: 'triple room',
        p1: 'The triple room offers air conditioning, refrigerator, a seating area, a balcony with garden views as well as a private bathroom boasting a shower. The unit has 3 beds.',
      },
      twinSea: {
        title: 'twin room sea side',
        p1: 'The twin room provides air conditioning, refrigerator, a seating area, a balcony with sea views as well as a private bathroom featuring a shower. The unit offers 2 beds.',
      },
      tripleSea: {
        title: 'triple room sea side',
        p1: 'The triple room offers air conditioning, refrigerator, a seating area, a balcony with sea views as well as a private bathroom boasting a shower. The unit has 3 beds.',
      },
    },
    kampus: {
      p1: `Rooms Kampus are located in Split, just 2 km from the UNESCO-protected Diocletian's Palace and a 20-minute walk from Žnjan beach. The complex features free WiFi, free parking on site, as well as a gym and a shared lounge with TV.`,
      p2: 'The property also feature an on-site bar, vending machines, as well as a sun terrace and 24-hour front desk service. Guests can also find a mini-market just a few steps away.',
      p3: 'The rooms feature single or twin beds, desks and wardrobes. Private bathrooms are available, fitted with a shower and toilet. Guests have access to a shared kitchen and dining area.',
      p4: 'The well-known Bacvice Beach is 1.7 km from Rooms Kampus. There are several bus stops nearby which provide easy access to the centre of town. The nearest airport is Split Airport, that can be reached in 14 km.',
      twin: {
        title: 'twin room',
        p1: 'This air-conditioned room features a desk, a wardrobe, refrigerator and access to a private bathroom with a shower. Room has two separate beds.',
      },
      single: {
        title: 'single room',
        p1: 'This air-conditioned room features a desk, a wardrobe, refrigerator and access to a private bathroom with a shower.',
      },
      twinDelux: {
        title: 'twin de-lux',
        p1: 'The twin/double room provides air conditioning, a wardrobe, refrigerator as well as a private bathroom featuring a shower. Rooms on the upper floors are reachable by lift. The unit offers 1 bed for two persons.',
      },
      doubleSuite: {
        title: 'double suite comfort',
        p1: 'This air-conditioned suite is comprised of 1 living room, 1 separate bedroom and 1 bathroom with a shower. The suite offers a wardrobe, a sofa, parquet floors, a flat-screen TV, refrigerator as well as sea views. The unit offers 3 beds.',
      },
      singleSuite: {
        title: 'single suite comfort',
        p1: 'The single room includes a private bathroom equipped with a shower. The air-conditioned single room offers a flat-screen TV, a wardrobe, refrigerator parquet floors, heating as well as sea views. The unit offers 1 bed.',
      },
    },
  },
  gallery: {
    title: 'gallery',
  },
  contactUs: {
    title: 'contact us',
  },
};
