import { useState } from 'react';
import Slider from 'react-slick';
import Constants from '../../../../utils/Constants';
import ScrollAnimation from '../../../../utils/ScrollAnimation';
import variables from '../../../../style/variables.scss';

const Units = ({
  lang,
  unitsList,
}: {
  lang: Locale.Keys;
  unitsList: Array<Unit>;
}) => {
  const [sliderTexts, setSliderTexts] = useState<Slider>();
  const [sliderImgs, setSliderImgs] = useState<Slider>();

  const slidesToShow = unitsList.length > 2 ? 3 : 1;

  const slickSettingsTitles = {
    centerMode: true,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    infinite: true,
    focusOnSelect: true,
    slidesToShow: slidesToShow,
    arrows: true,
    speed: 500,
    slidesToScroll: 1,
    asNavFor: sliderImgs,
    prevArrow: (
      <div className='opacity_transition_low'>
        {Constants.arrowLeft(variables.primaryColor)}
      </div>
    ),
    nextArrow: (
      <div className='opacity_transition_low'>
        {Constants.arrowRight(variables.primaryColor)}
      </div>
    ),
  };

  const slickSettingsImgs = {
    fade: true,
    infinite: true,
    slidesToShow: 1,
    arrows: false,
    speed: 500,
    slidesToScroll: 1,
    asNavFor: sliderTexts,
  };

  return (
    <div className='units'>
      <div className='units_container container'>
        <ScrollAnimation animateIn='fadeIn' delay={100}>
          <h2 className='text_center'>{lang.home.accommodations.title}</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeIn' delay={150}>
          <Slider
            {...slickSettingsTitles}
            ref={(slider) =>
              slider ? setSliderTexts(slider) : setSliderTexts(undefined)
            }
            className='units_container_slick_title'
          >
            {unitsList.map((x) => (
              <>
                <div className='units_container_slick_title_container'>
                  {x.unitTitle}
                </div>
              </>
            ))}
          </Slider>
          <Slider
            {...slickSettingsImgs}
            ref={(slider) =>
              slider ? setSliderImgs(slider) : setSliderImgs(undefined)
            }
            className='units_container_slick'
          >
            {unitsList.map((x) => (
              <>
                <p>{x.unitDescription}</p>
                <div className='units_container_slick_container'>
                  <img
                    src={x.unitImg}
                    alt={x.unitTitle}
                    className='units_container_slick_container_image'
                  />
                </div>
              </>
            ))}
          </Slider>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Units;
