import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useLanguageSwitcher } from './locale/useLanguageSwitcher';
import { en } from './locale/en';
import { hr } from './locale/hr';
import Layout from './pages/Layout';
import Home from './pages/home/Home';
import OnReloadScrollToTop from './utils/OnReloadScrollToTop';
import Constants from './utils/Constants';
import { useEffect } from 'react';
import Spinut from './pages/hotels/spinut/Spinut';
import Bb from './pages/hotels/bb/Bb';
import Kampus from './pages/hotels/kampus/Kampus';

const App: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { currentLanguage, changeLanguage, getLanguageFromURL } =
    useLanguageSwitcher();

  const lang: Locale.Keys = currentLanguage === Constants.lang.en ? en : hr;

  let doc: HTMLElement = document.documentElement;

  OnReloadScrollToTop();

  const setHight = () => {
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };

  window.addEventListener('resize', setHight);

  useEffect(() => {
    setHight();
  }, []);

  useEffect(() => {
    getLanguageFromURL();
  }, [getLanguageFromURL]);

  return (
    <Routes>
      <Route
        path='/'
        element={
          <Layout
            currentLanguage={currentLanguage}
            changeLanguage={changeLanguage}
            lang={lang}
            location={location}
            navigate={navigate}
          />
        }
      >
        <Route
          index
          element={<Home lang={lang} location={location} navigate={navigate} />}
        />
        <Route path={Constants.SPINUT} element={<Spinut lang={lang} />} />
        <Route path={Constants.BB} element={<Bb lang={lang} />} />
        <Route path={Constants.KAMPUS} element={<Kampus lang={lang} />} />
      </Route>
    </Routes>
  );
};

export default App;
