import twinRoomImg from '../../../assets/imgs/home/hero/01_spinut.webp';
import superiorTwinImg from '../../../assets/imgs/home/hero/02_bb.webp';

const SpinutUnitsList = ({ lang }: { lang: Locale.Keys }) => {
  const twinRoom: Unit = {
    unitTitle: lang.hotels.spinut.twin.title,
    unitDescription: lang.hotels.spinut.twin.p1,
    unitImg: twinRoomImg,
  };

  const superirorTwin: Unit = {
    unitTitle: lang.hotels.spinut.superiorTwin.title,
    unitDescription: lang.hotels.spinut.superiorTwin.p1,
    unitImg: superiorTwinImg,
  };

  return [twinRoom, superirorTwin];
};

export default SpinutUnitsList;
