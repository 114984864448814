import ScrollAnimation from '../../../../utils/ScrollAnimation';
import logo from '../../../../assets/imgs/scst_logo.png';
import Constants from '../../../../utils/Constants';

const About = ({ lang }: { lang: Locale.Keys }) => {
  return (
    <div id={Constants.ABOUT} className='about'>
      <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
        <div className='about_container container'>
          <ScrollAnimation animateIn='fadeInUp' delay={100}>
            <div className='about_container_logo'>
              <img src={logo} alt={Constants.PROPERTY_NAME + ' large logo'} />
            </div>
          </ScrollAnimation>
          <div className='about_container_text'>
            <ScrollAnimation animateIn='fadeInUp' delay={150}>
              <h2 className='title_underline_light'>{lang.home.about.title}</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' delay={200}>
              <p>{lang.home.about.p1}</p>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' delay={250}>
              <p>{lang.home.about.p2}</p>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' delay={300}>
              <p>{lang.home.about.p3}</p>
            </ScrollAnimation>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
};

export default About;
