import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import Constants from '../../../../utils/Constants';
import ScrollAnimation from '../../../../utils/ScrollAnimation';
import variables from '../../../../style/variables.scss';
import DiscoverList from './DiscoverList';

const Discover = ({ lang }: { lang: Locale.Keys }) => {
  const transitionSpeed: number = 500;

  const discoverList = DiscoverList({ lang });

  const imgRef = useRef<Slider>(null);
  const textRef = useRef<Slider>(null);

  const [activeSlide, setActiveSlide] = useState<number>(0);

  const next = () => {
    imgRef.current?.slickNext();
    textRef.current?.slickNext();
  };

  const prev = () => {
    imgRef.current?.slickPrev();
    textRef.current?.slickPrev();
  };

  useEffect(() => {
    setActiveSlide(0);
  }, []);

  const slickImage = {
    adaptiveHeight: false,
    draggable: false,
    swipe: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slickText = {
    adaptiveHeight: false,
    draggable: false,
    swipe: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current: number, next: number) => {
      setActiveSlide(next);
    },
  };

  return (
    <div id={Constants.DISCOVER} className='discover'>
      <div className='discover_container container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeInUp' duration={500} dellay={100}>
            <h2 className='text_center'>{lang.home.discover.title}</h2>
          </ScrollAnimation>
          <div className='discover_container_content'>
            <ScrollAnimation animateIn='fadeInUp' dellay={150}>
              <div className='discover_container_content_text'>
                <Slider ref={textRef} {...slickImage}>
                  {discoverList.map((discover) => (
                    <>{discover.description}</>
                  ))}
                </Slider>
                <div className='discover_container_content_buttons'>
                  <div
                    className='opacity_transition_low'
                    onClick={() => prev()}
                  >
                    {Constants.arrowLeft(variables.primaryColor)}
                  </div>
                  <div
                    className='opacity_transition_low'
                    onClick={() => next()}
                  >
                    {Constants.arrowRight(variables.primaryColor)}
                  </div>
                  <div className='discover_container_content_buttons_counter'>
                    {activeSlide + 1} / {discoverList.length}
                  </div>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' dellay={200}>
              <div className='discover_container_content_img'>
                <Slider ref={imgRef} {...slickText}>
                  {discoverList.map((discover) => (
                    <img
                      src={discover.imgPath}
                      alt={`${Constants.DISCOVER} ${
                        discoverList[discoverList.indexOf(discover)]
                      }`}
                    />
                  ))}
                </Slider>
              </div>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Discover;
