import hostelSpinutImg from '../../assets/imgs/home/hero/01_spinut.webp';
import hostelBb from '../../assets/imgs/home/hero/02_bb.webp';
import roomsKampus from '../../assets/imgs/home/hero/03_kampus.webp';
import Constants from '../../utils/Constants';

const HeroSlideshowList = ({ lang }: { lang: Locale.Keys }) => {
  return [
    {
      img: hostelSpinutImg,
      title: lang.home.hero.hostelSpinut,
      link: Constants.SPINUT,
    },
    { img: hostelBb, title: lang.home.hero.hostelBb, link: Constants.BB },
    {
      img: roomsKampus,
      title: lang.home.hero.roomsKampus,
      link: Constants.KAMPUS,
    },
  ];
};

export default HeroSlideshowList;
