import { Location, NavigateFunction } from 'react-router-dom';
import Slider from 'react-slick';
import Constants from '../../../../utils/Constants';
import ScrollAnimation from '../../../../utils/ScrollAnimation';
import HeroSlideshowList from '../../../../components/hero_slideshow/HeroSlideshowList';
import scrollToPage from '../../../../utils/ScrollToLink';

const Accommodations = ({
  lang,
  location,
  navigate,
}: {
  lang: Locale.Keys;
  location: Location;
  navigate: NavigateFunction;
}) => {
  const accommodationsList = HeroSlideshowList({ lang });

  const slickSettingsImgs = {
    focusOnSelect: true,
    infinite: true,
    centerMode: true,
    centerPadding: '200px',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '120px',
        },
      },
      {
        breakpoint: 801,
        settings: {
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div id={Constants.ACCOMMODATIONS} className='accommodations'>
      <div className='accommodations_container container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
            <h2 className='text_center'>{lang.home.accommodations.title}</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeIn' delay={150}>
            <Slider {...slickSettingsImgs}>
              {accommodationsList.map((accommodation) => (
                <div className='accommodations_container_slider_container'>
                  <div className='accommodations_container_slider_container_img'>
                    <img src={accommodation.img} alt={accommodation.title} />
                  </div>
                  <div className='accommodations_container_slider_container_title'>
                    <h2 className='text_center'>{accommodation.title}</h2>
                    <div
                      className='opacity_transition_high'
                      onClick={() =>
                        scrollToPage(
                          accommodation.link,
                          location,
                          navigate,
                          true
                        )
                      }
                    >
                      {lang.showMore.toUpperCase()}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </ScrollAnimation>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Accommodations;
