import ScrollAnimation from '../../../../utils/ScrollAnimation';
import Variables from '../../../../style/variables.scss';
import { Service } from './_services';

const Services = ({
  lang,
  services,
}: {
  lang: Locale.Keys;
  services: Array<Service>;
}) => {
  const iconsHeight: number = 24;

  return (
    <div className='services'>
      <div className='services_container container'>
        <ScrollAnimation animateIn='fadeIn' dellay={100}>
          <h2 className='text_center'>{lang.hotels.services.title}</h2>
        </ScrollAnimation>
        <div className='services_container_details'>
          {services.map((service) => (
            <ScrollAnimation
              animateIn='fadeInUp'
              duration={500}
              delay={services.indexOf(service) * 50 + 100}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox={service.viewBox}
                height={iconsHeight}
                fill={Variables.primaryColor}
              >
                {service.path}
              </svg>
              {service.description.toUpperCase()}
            </ScrollAnimation>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
